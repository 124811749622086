@import "../styles/theme";

.gradientText {
  background: linear-gradient(180deg, $main-color2 1.61%, $main-color1 98.38%);
  background-clip: text;
  color: transparent;
}

// mobile pool item
.MItem {
  border-top: 1px solid rgba($main-color3, 0.1);
  margin-top: 16px;
  padding-top: 24px;
  &Sub {
    color: $main-color3;
    text-align: left;
    margin-top: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  &Text {
    font-size: 16px;
    line-height: 16px;
    margin-top: 8px;
  }
}
