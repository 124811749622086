@import "../styles/theme";

div.poolInfoCard {
  color: var(--text-darker);
  font-size: 16px;
  font-weight: $normal;
  border-top: 1px solid rgba($color: #ebebeb, $alpha: 0.2);
  .currency-reserves {
    background: transparent;
    padding: 20px 20px;
    font-size: 14px;
    border-bottom: 1px solid rgba($color: #ebebeb, $alpha: 0.2);
    .title {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
    }
    .intro {
      color: rgba(#ebebeb, 0.5);
    }
    .total {
      font-weight: 300;
      color: #0f173c;
    }
  }

  h4 {
    width: 100%;
    font-family: $font-family-title;
    margin-top: 24px;
    margin-right: 0;
    margin-bottom: 24px;
    font-size: 30px;
    font-weight: $bold;
    color: var(--text-title);
  }

  div.info {
    background: transparent;
    padding: 10px 20px 20px 20px;
    color: #ebebeb;
    font-size: 14px;
    .title {
      font-weight: 700;
      margin-bottom: 8px;
    }
    .MuiAccordionSummary-expandIconWrapper {
      color: #ebebeb;
    }
    .MuiAccordionSummary-content {
      margin: 0;
    }
    .MuiAccordionDetails-root {
      display: grid;
      grid-template-rows: auto;
      row-gap: 8px;
    }
    .infoItem {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
    }
    .label {
      margin-right: 8px;
    }
    .value {
      // font-weight: 600;
    }
    .underline {
      border-bottom: 1px dotted var(--text);

      &:hover {
        cursor: help;
      }
    }
  }

  .bottom {
    h4 {
      margin-bottom: 4px;
    }
  }

  .twoColumn {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;

    span {
      display: block;
      line-height: 20px;
    }
  }

  .tokenList {
    display: flex;
    flex-wrap: wrap;

    img {
      vertical-align: text-bottom;
      height: 24px;
      width: 24px;
      margin-right: 8px;
      display: inline-block;
      transform: translateY(3px);
    }

    .token {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 12px;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .percent {
        color: $primary;
      }
    }

    span.tokenPercent {
      margin-left: 16px;
      font-weight: $medium;
    }

    span.tokenValue {
      display: block;
      margin-top: 8px;
    }
  }

  .bold {
    font-weight: $bold;
  }

  .underline {
    border-bottom: 1px dotted var(--text);
  }
}
