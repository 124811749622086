@import "../styles/theme";

div.connectWallet {
  color: $main-color4;
  padding: 40px;
  // background: $main-color4;
  border-radius: 8px;

  & > h3 {
    font-weight: $bold;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 16px;
    color: var(--text-darker);
  }

  div.walletList {
    display: grid;
    grid-template-rows: auto;
    row-gap: 16px;

    // margin: 0 -24px;
    // padding: 32px;

    & > button {
      // color: var(--text);
      outline: none;
      //border: 1px solid var(--outline);
      border-radius: 10px;
      width: 100%;
      padding: 25px 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      height: 82px;

      background: linear-gradient(
        126.6deg,
        rgba(255, 255, 255, 0.06) 28.69%,
        rgba(255, 255, 255, 0) 100%
      );
      backdrop-filter: blur(100px);
      border: 3px solid rgba(255, 255, 255, 0.1);
      color: #ebebeb;
      font-weight: 700;

      &:hover {
        cursor: pointer;
        // padding: 15px;
        border: 3px solid rgba(255, 255, 255, 0.3);
        //border: 2px solid var(--text-selected);
      }
    }
    .icon-box {
      display: flex;
      align-items: center;
    }
    img.icon {
      width: 40px;
    }

    span {
      line-height: 24px;
      // font-size: 16px;
    }
  }

  p {
    // padding: 24px 32px 32px 32px;

    // margin: -24px;
    // background: var(--background);
    // border-radius: 8px;
    font-size: 14px;
    line-height: 22px;
    padding-top: 15px;
    text-align: left;
    a {
      // color: var(--text-selected);
      // font-weight: $medium;
      color: #ebebeb;

      text-decoration: none;
      font-weight: 600;
      &:hover {
        color: $main-color2;
      }
    }
  }
}

.DialogConnectWallet {
  border: 1px solid #6c7dff;
}

#walletconnect-wrapper {
  color: #000;
}
