@import "../styles/theme.scss";
@import "../styles/breakpoints";

.ReviewLock {
  background-color: transparent;
  @include breakpoint-down(sm) {
    background-color: var(--primary-background);
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .close-btn {
    cursor: pointer;
  }
  .label,
  .value {
    font-size: 14px;
  }
  .value {
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-left: 4px;
    }
  }
  .top {
    border-bottom: 1px solid rgba($color: #ebebeb, $alpha: 0.1);
  }
  .alert {
    background-color: rgba(#ffcdec, 0.1);
    color: #ffcdec;
    line-height: 42px;
    padding: 16px;
    border-radius: 8px;
    img {
      margin-right: 4px;
    }
  }
  .lock-btn {
    border-radius: 0;
    height: 64px;
    line-height: 64px;
    font-size: 20px;
    font-weight: 700;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
