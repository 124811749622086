@import "../styles/theme";
@import "../styles/breakpoints";

.TabBtnGroup {
  display: flex;
  justify-content: center;
  width: 280px;
  margin: 0 auto 40px;
  border-radius: 9999px;
  border: 1px solid #554979;
  font-weight: 600;
  @include breakpoint-down(sm) {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }

  span {
    display: block;
    width: 50%;
    height: 32px;
    background: none;
    text-align: center;
    color: $main-color3;
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
    border-radius: 9999px;
    cursor: pointer;
    @include breakpoint-down(sm) {
      font-size: 12px;
    }
    &.active {
      background: $main-color2;
    }

    &:nth-child(3),&:nth-child(4) {
      display: none;
      @include breakpoint-down(sm) {
        display: inline;
      }
    }

  }
  .active {
    background: $main-color2;
    color: $main-color4;
  }
}

.FarmDetailRight {
  padding-top: 24px;
  // margin-bottom: 14px;
  height: 400px;
  .swap_btn_wrapper {
    margin: 0;
    
  }
}

.FarmDetailRightContent {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 225px;

  .t1 {
    font-size: 20px;
    font-weight: 600;
  }
  .t2 {
    font-size: 12px;
  }
  input {
    font-size: 24px;
    background: none;
    text-align: center;
    border: 0;
    outline: none;
    color: $main-color3;
    font-weight: 700;
    @include breakpoint-down(sm) {
      font-size: inherit;
    }
  }
  .progress_button_group {
    width: 280px;
  }

}
.Farm_Lock_ad {
  font-size: 14px;
  text-align: center;

  margin-bottom: 14px;
  a {
    text-decoration: none;
  }
}
.FarmNoBorder {
  border: 0!important;
  background: none!important;
  backdrop-filter: inherit!important;
  margin-bottom: -10px!important;
}
.FarmRight_bot {
  position: fixed;
  padding-left: 0!important;
  bottom: 62px;
  left: 0;
  right: -32px;

}