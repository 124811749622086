// Breakpoint viewport sizes and media queries
// Value reference mui: https://mui.com/material-ui/customization/breakpoints/
// Method reference Bootstrap: https://github.com/twbs/bootstrap

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 900px,
  lg: 1200px,
  xl: 1536px,
) !default;

// Name of the next breakpoint, or null for the last breakpoint
// >> breakpoint-next(sm)
// md
@function breakpoint-next(
  $name,
  $breakpoints: $grid-breakpoints,
  $breakpoint-names: map-keys($breakpoints)
) {
  $n: index($breakpoint-names, $name);
  @if not $n {
    @error "breakpoint `#{$name}` not found in `#{$breakpoints}`";
  }
  @return if(
    $n < length($breakpoint-names),
    nth($breakpoint-names, $n + 1),
    null
  );
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint
// >> breakpoint-min(sm)
// 600px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. The maximum value is reduced by 0.02px
// >> breakpoint-max(md)
// 899.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - 0.02, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint
// Makes the @content apply to the given breakpoint and wider
@mixin breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint
// Makes the @content apply to the given breakpoint and narrower
@mixin breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths
// Makes the @content apply between the min and max breakpoints
@mixin breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths
// No minimum for the smallest breakpoint, and no maximum for the largest one
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower
@mixin breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $next: breakpoint-next($name, $breakpoints);
  $max: breakpoint-max($next, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include breakpoint-down($next, $breakpoints) {
      @content;
    }
  }
}
