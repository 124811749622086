/**
* GLOBAL STYLES
* Imported once at the top level
* Used everywhere
*/
@import "theme";

* {
  box-sizing: border-box;
  margin: 0px;
  font-family: "Poppins", sans-serif; //"Source Code Pro", monospace, sans-serif;
}
legend {
  height: 11px;
}
body {
  background-image: url(./../assets/bg.png),
    linear-gradient(0deg, #ffcdec -83.19%, #150847 55.63%);
  background-color: #150847;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  position: relative;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}
p,
b,
span {
  font-family: $font-family;
  line-height: 20px;
  size: 16px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family-title;
  color: #ebebeb;
}
input {
  border: none;
  font-size: 100%;
}

svg,
img {
  // TODO: get rid of this once chakra is more integrated
  display: inline !important;
}

ul {
  list-style: none;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiDialog-paperScrollBody {
  border: 0;
  border-radius: 16px;
}

:root {
  --primary-foreground: #000;
  --primary-background: #fff;
  --background: #fff;
  --background-lightgrey: #dfdde3;
  --background-element: #ffffff;
  --background-element-hover: rgba(255, 255, 255, 0.88);
  --background-main: linear-gradient(180deg, #ffffff 0%, #f9f2cd 98.96%);
  --background-logo: #{$primary};
  --secondary-button: #{$light-blue};
  --emphasis-1: #{$sand2}; // passive state
  --emphasis-2: #{$sand1}; // active state
  --primary-accent: #{$primary-blue};
  --outline: #000;
  --outline-gray: #{$black30};
  --option-hover: #{$primary-blue-light};
  --tab: #{$primary};

  --text: #0f173c;
  --text-darker: #000;
  --text-lighter: #686868;
  --text-lightest: #a5a5a5;
  --text-title: #{text-black};
  --text-primary: #{$primary-blue};
  --text-selected: #{$primary-blue};
  --text-secondary-button: #{$primary-blue};
  --text-secondary-button-disabled: #{$indigo20T};
  --header-text: #1f2023;
  --sign-graph: #000;

  --shadow: 0px 2px 8px rgba(39, 14, 1, 0.1);
  --shadow-hover: 2px 2px 12px rgba(68, 64, 64, 0.2);
}

// Dark mode themes
body.dark {
  --primary-foreground: #ebebeb;
  --primary-background: #35235e;
  --background: #000;
  --background-lightgrey: #{$black80};
  --background-element: #35235e;
  --background-element-hover: rgba(0, 0, 0, 0.8);
  --background-main: linear-gradient(180deg, #010101 49.48%, #472db1 100%);
  --background-logo: #ffffe9;
  --secondary-button: #{$dark-purple};
  --emphasis-1: #{$indigo3}; // passive state
  --emphasis-2: #{$indigo2}; // active state
  --primary-accent: #{$primary};
  --outline: #ebebeb;
  --option-hover: #{$primary-blue-dark};
  --outline-gray: #{$white30};
  --tab: #{$white};

  --text: #{$main-color3};
  --text-darker: #ffffe9;
  --text-lighter: #bbbbbb;
  --text-lightest: #888888;
  --text-title: #ffffe9;
  --text-primary: #{$primary};
  --text-selected: #{$primary-blue-dark};
  --text-secondary-button: #{$blue};
  --text-secondary-button-disabled: #{$blue40T};
  --header-text: #{$primary};
  --sign-graph: #{$primary-blue};

  --shadow: 0px 2px 8px rgba(39, 14, 1, 0.18);
  --shadow-hover: 2px 2px 12px rgba(68, 64, 64, 0.14);
}

.pointer {
  cursor: pointer;
}

.CustomSnackbar {
  background-color: var(--primary-background) !important;
  color: $main-color3 !important;
}

.g-link {
  display: inline-block;
  color: $main-color2;
  border-bottom: 1px dashed;
  text-decoration: none;
  line-height: 1.2;
  cursor: pointer;
}

.hide {
  display: none !important;
}
