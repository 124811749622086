@import "../styles/theme";
@import "../styles/viewports";
@import "../styles/breakpoints";

div.pool-item {
  color: #ebebeb;
  cursor: pointer;
  border-bottom: 1px solid rgba($color: #ebebeb, $alpha: 0.2);
  &:hover {
    background-color: #554979;
  }
}

div.poolOverview {
  margin-top: 40px;
  width: 100%;
  background: var(--background-element);
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.18);
  // border: 1px solid var(--outline);
  border-radius: 20px;
  padding: 24px 32px;
  display: grid;
  grid-template-columns: 50% 50%;
  @include breakpoint(md) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    row-gap: 24px;
    padding: 24px 6%;
    width: 80%;
  }
  @include breakpoint(sm) {
    width: 90%;
    padding: 24px 2%;
  }
  @include breakpoint(xs) {
    width: 100%;
    padding: 24px 2%;
  }

  .left {
    display: grid;
    height: 140px;
    grid-template-rows: 40px 24px 22px 10px 20px;
    border-right: 2px solid #aca8bc;
    .pool-name {
      margin-left: 8px;
      p {
        font-size: 12px;
        font-weight: 400;
      }
      .title {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .titleAndTag {
    grid-row: 1/2;
    display: flex;
    img {
      border: 1px solid $primary;
      border-radius: 10px;
    }
  }

  h4.title {
    font-family: $font-family-title;
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 30px;
    font-weight: $bold;
    color: var(--text-title);
    margin-right: 16px;
  }

  .balance {
    grid-row: 3/4;
  }
  .tokens {
    grid-row: 5/6;
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    @include breakpoint(md) {
      width: 100%;
      justify-content: space-between;
    }
  }

  .assets-title {
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
  }

  div.token {
    margin-right: 4px;
    width: 90px;
    height: 36px;
    // padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $primary;
    border-radius: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  div.right {
    display: grid;
    justify-content: end;
    @include breakpoint(md) {
      justify-content: space-between;
      row-gap: 24px;
      grid-template-columns: 100%;
    }

    .margin {
      margin-right: 24px;
      text-align: center;
      @include breakpoint(md) {
        margin-right: auto;
      }
    }
    .poolInfo {
      display: flex;
      width: 100%;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-self: end;
      @include breakpoint(md) {
        justify-self: start;
        width: 100%;
        justify-content: space-between;
      }
    }
    span.label {
      display: block;
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: 400;
      // font-weight: $bold;
    }
    span.value {
      font-weight: 600;
    }

    .plus {
      font-weight: $medium;
      color: var(--text-selected);
      display: block;
    }
    .minus {
      font-weight: $medium;
      color: $error;
    }

    .buttons {
      align-self: end;
      justify-self: end;
      display: grid;
      grid-template-columns: 176px 176px;
      column-gap: 16px;
      @include breakpoint(md) {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
  .underline {
    border-bottom: 1px dotted var(--text);
  }
}

div.outdated {
  border-color: $yellow2;
}

div.poolOverview:first-child {
  margin-top: 0;
}

.red-btn {
  background: linear-gradient(136deg, #ff7c95 0%, #ffb37e 100%) !important;
  color: #fff;
  &:disabled {
    background: $disabled !important;
  }
}

.blue-btn {
  background: linear-gradient(136deg, #4b89ff 0%, #3fe2fa 100%) !important;
  color: #fff;
  &:disabled {
    background: $disabled !important;
  }
}
