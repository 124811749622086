@import "../styles/theme";
@import "../styles/viewports";
@import "../styles/breakpoints";

.searchSelect {
  padding: 16px 0px;
  @include breakpoint-up(sm) {
    max-height: 350px;
    width: 450px;
    max-width: 100%;
    overflow-y: auto;
  }
}

.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;

  input {
    flex: 1;
    height: 32px;
    background-color: var(--primary-background);
    margin: 0 8px;
    padding: 0 8px;
    color: var(--primary-foreground);
    font-size: 16px;
    font-family: $font-family;
    font-style: normal;
    font-weight: $normal;

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: $disabled;
      border: none;
    }
  }
}
.listContainer {
  padding: 0px;
  .listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 24px;
    // margin: 8px 0;
    user-select: none;

    img {
    }

    > :nth-child(2) {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0 10px;
    }
    > :nth-child(3) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    &.isAvailable {
      &:hover,
      &.isActive {
        background-color: var(--option-hover);
        .tagWrapper {
          color: $main-color2;
        }
        cursor: pointer;
      }
    }
    &.isUnavailable {
      b,
      p,
      span {
        color: var(--text-lightest);
      }
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

.textMinor {
  font-size: 12px;
  color: var(--text-lighter);
}

.tagWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
