@import "../styles/theme";

.bn-notify-custom {
  &.bn-notify-dark-mode {
    background: $indigo3 !important;
    border: 1px solid $primary;
  }
  &.bn-notify-notification {
    &s {
      width: 320px;
    }
    &-info-message {
      font-family: $font-family;
      font-weight: $medium;
      a {
        color: $primary;
        text-decoration: underline;
      }
    }
    &-info-meta-duration {
      display: none;
    }
    &-close-icon > svg {
      height: 12px;
      width: 12px;
    }
    &-pending &-status-icon * {
      stroke: $main-color3 !important;
      // animation-iteration-count: 0 !important;
    }
    &-error {
      border-color: $error;
    }
    // &-error &-status-icon * {
    //   stroke: $error !important;
    //   animation-duration: 0s !important;
    // }
    &-error &-info-message a {
      color: $error;
    }
    &-success &-status-icon * {
      stroke: $primary !important;
      // animation-duration: 0s !important;
    }
  }
}
