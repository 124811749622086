@import "../styles/theme";
@import "../styles/breakpoints";

div.reviewSwap {
  margin-top: 0;
  // padding: 40px;
  // background-color: $white;
  // color: $text-black;
  border-radius: 8px;
  @include breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .reviewSwapContainer {
      flex-grow: 1;
    }
  }

  h3 {
    font-family: $font-family-title;
    font-size: 30px;
    font-weight: $bold;
    color: $text-black;
    margin-top: 0;
    margin-bottom: 24px;
  }

  .swapTable {
    margin-bottom: 36px;

    .floatRight {
      float: right;
    }

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      // margin-top: 16px;
    }

    svg.stepArrow {
      margin-right: 16px;
      stroke: var(--outline);
    }

    .aside {
      font-size: 11px;
    }

    .grey {
      color: var(--text-lighter);
    }

    .arrowDown {
      margin-top: 8px;
      margin-bottom: 8px;
      margin-left: auto;
      float: right;
    }

    img.tokenIcon {
      vertical-align: text-bottom;
    }

    span.tokenName {
      margin-left: 8px;
      font-weight: $bold;
    }

    .divider {
      background-color: #eff4ff;
      margin: 16px 0 20px 0;
    }

    .priceTable {
      width: 100%;
      color: $text-black;
    }

    .row {
      // margin-top: 8px;
    }
  }

  .bottom {
    background: #eff4ff;
    color: #000;
    margin: 0 -40px -40px -40px;
    padding: 24px 40px 40px 40px;
    border-radius: 0px 0px 8px 8px;

    p {
      margin: 0 0 32px 0;
      line-height: 20px;
    }

    .buttonWrapper {
      display: flex;
      justify-content: space-around;
      button {
        width: 40%;
      }
    }
  }
}

.reviewSwap {
  h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    span {
      cursor: pointer;
      margin-right: -2px;
    }
  }
  .directSwapItem {
    display: flex;
    align-items: center !important;
    padding-bottom: 24px;
    > div {
      display: flex;
      align-items: center;
      .tokenName {
        font-size: 20px;
        font-weight: 700;
        margin-left: 8px;
      }
    }
    > div:nth-child(2) {
      font-size: 24px;
      font-weight: 700;
    }
  }
  .rs_token_subtitle {
    margin-bottom: 8px;
    margin-top: 16px;
  }
}
.swapInfo {
  .row {
    font-size: 14px;
    margin-bottom: 7px;
    .floatRight {
      display: flex;
      align-items: center;
    }
  }
}
.important-text {
  color: $main-color2;
  font-weight: 700;
}
