@import "../styles/theme";
@import "../styles/breakpoints";

.LeftItem {
  display: flex;
  height: 100%;
  padding: 16px;
  @include breakpoint-down(sm) {
    align-items: center;
    &Icon {
      height: 40px;
      padding-right: 16px;
    }
  }
  @include breakpoint-up(sm) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

.ListItem {
  cursor: pointer;
  padding: 16px 40px;
  @include breakpoint-down(md) {
    padding: 24px;
  }
  &Text {
    color: $main-color2;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    @include breakpoint-down(sm) {
      position: absolute;
      top: 16px;
      right: 24px;
    }
  }
  &Time {
    font-size: 0.75rem;
    font-weight: 400;
    color: rgba(white, 0.7);
  }
  &Amount {
    color: $main-color3;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-top: 4px;
    @include breakpoint-up(sm) {
      position: absolute;
      top: 8px;
      right: 24px;
    }
    @include breakpoint-up(md) {
      right: 40px;
    }
  }
}
