@import "../styles/theme";
@import "../styles/breakpoints";

.Event {
  &Item {
    color: $main-color3;
    text-decoration: unset;
    transition: 0.2s;
    &Active,
    &:hover {
      color: $main-color2;
    }
  }
  &Tag {
    width: 74px;
    line-height: 24px;
    font-size: 12px;
    color: $main-color1;
    background-color: rgba($main-color1, 0.2);
    border-radius: 50px;
    text-align: center;
    transition: 0.2s;
    &Ongoing {
      color: $main-color2;
      background-color: rgba($main-color2, 0.2);
    }
    &Upcoming {
      color: $sand1;
      background-color: rgba($sand1, 0.2);
    }
  }
  &Desc {
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;
    a {
      color: $main-color2;
      text-decoration: none;
    }
  }
}

.RightBox {
  border-radius: 16px;
  overflow: hidden;
  @include breakpoint-up(sm) {
    background-color: var(--primary-background);
  }
}

.Btn {
  width: 100%;
  height: 56px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  font-size: 20px !important;
  background-color: $main-color1 !important;
  color: $main-color3 !important;
  transition: 0.25s !important;
  &:hover {
    background-color: $main-color2 !important;
    color: $main-color4 !important;
    opacity: 1 !important;
  }
  &[disabled] {
    background-color: $disabled !important;
    color: $indigo3 !important;
  }
  &Loading {
    vertical-align: middle;
    animation: loading-spin 1s infinite linear;
    @keyframes loading-spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }
  }
  @include breakpoint-down(sm) {
    &Mobile {
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }
}
