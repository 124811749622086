@import "../styles/theme";
@import "../styles/viewports";
@import "../styles/breakpoints";

.info {
  border-radius: 16px;
  background: linear-gradient(
    126.6deg,
    rgba(255, 255, 255, 0.06) 28.69%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 3px solid rgba(255, 255, 255, 0.1);
  padding: 32px 0;
}
.thead {
  border-bottom: 1px solid rgba($color: #ebebeb, $alpha: 0.2);
}
.list {
  border-radius: 16px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 32px;
  background: linear-gradient(
    126.6deg,
    rgba(255, 255, 255, 0.06) 28.69%,
    rgba(255, 255, 255, 0) 100%
  );
}
.tvl {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin: 32px 0;
}
div.poolsPage {
  min-height: 100vh;
  color: var(--text);
  font-size: 16px;
  padding-bottom: 80px;

  .filters {
    width: max-content;
    margin: 24px auto;
    display: flex;
    @include breakpoint(sm) {
      padding-left: 0px;
    }
  }

  .filterTab {
    width: max-content;
    padding: 2px 32px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;

    &.selected {
      background-color: var(--tab);
      color: var(--primary-background);
      font-weight: $bold;
    }
  }

  .outdated {
    color: $yellow;

    &.selected {
      background-color: $yellow;
      color: $text-black;
    }
  }

  .content {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include breakpoint(sm) {
      width: 100%;
    }
  }
}
.alert {
  background-color: rgba(#ffcdec, 0.1);
  color: #ffcdec;
  line-height: 42px;
  padding: 16px;
  border-radius: 8px;
  img {
    margin-right: 4px;
  }
}