@import "../styles/theme";

.top_menu_others_con {
  padding: 24px;
  > div {
    margin-top: 5px;
  }
  h6 {
    font-weight: 700;
  }
  a,span {
    color: $main-color3;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    &:hover, &.active {
      color: $main-color2;
    }
  }
  span {
    display: flex;
    padding: 2px;
  }
  .TopMenuOthersIcons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    a,span {
      margin-top: 10px;
      margin-right: 10px;
    }
  }
}
