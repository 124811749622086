@import "../styles/theme";
@import "../styles/breakpoints";

.settingFrame {
  // position: absolute;
  // right: 0;
  // margin-top: -22px;
  // z-index: 120;
  > button.button,
  > button.button.outline:focus,
  > button.button.outline:focus-visible {
    width: 32px;
    height: 32px;
    padding: 0;
    box-sizing: border-box;
    border-color: transparent;
    color: $main-color3;
    &:hover,
    &[aria-describedby] {
      border-color: $main-color2 !important;
      color: $main-color2 !important;
    }
  }

  // button,
  // input {
  //   height: 28px;
  //   padding-top: 0;
  //   padding-bottom: 0;
  // }
  // .MuiToggleButtonGroup-root {
  //   height: 28px;
  // }
  // .MuiTextField-root {
  //   height: 28px;
  // }
}
.adv_paper_container {
  transform: translate(0, 1em);
  h6 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      cursor: pointer;
    }
  }
  .adv_paper {
    padding: 24px;
    @include breakpoint-up(sm) {
      width: 317px;
    }
    box-sizing: border-box;
  }
}
.AdvBtnGroup {
  width: auto !important;
  > button {
    border: 1px solid $main-color2;
    color: $main-color2;
    height: 24px;
    border-radius: 999999px !important;
    // padding-left: 0;
    // padding-right: 0;
    width: 60px;
    font-size: 12px;
    font-weight: 600;
    @include breakpoint-down(sm) {
      height: 32px;
      width: 50%;
    }

    &.MuiToggleButtonGroup-grouped:not(:first-of-type) {
      margin-left: 10px;
      border-left-width: 1px;
      border-left-color: inherit;
    }

    &.Mui-selected {
      background-color: $main-color2;
      color: $main-color4;
      &:hover {
        background: $main-color2;
        color: $main-color4;
        border-color: $main-color2;
      }
      &.MuiToggleButtonGroup-grouped:not(:first-of-type) {
        border-color: $main-color2;
      }
    }
    &:hover {
      background: none;
      color: $main-color3;
      border-color: $main-color3;
    }
  }
}
.advBtnGroup_input {
  &.gas_input {
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }
  @include breakpoint-up(sm) {
    &:not(.gas_input) {
      margin-left: 10px !important;
    }
  }
  .MuiOutlinedInput-root {
    color: $main-color2;
    :hover {
      // color: $main-color3;
    }
    .MuiTypography-root {
      color: $main-color2;
    }
  }
  input {
    height: 24px;
    box-sizing: border-box;
    font-size: 12px;
    text-align: center;
    @include breakpoint-down(sm) {
      height: 32px;
    }
    &::placeholder {
      // color: #554979;
      font-weight: 700;
    }
  }
  fieldset {
    border-color: $main-color2;
    border-radius: 99999px !important;
  }
  &:not([class*="noValue"]) {
    /* Your style here */
    .MuiOutlinedInput-root:not([class*="Mui-focused"]) {
      background: $main-color2;
      color: $main-color4;
      border-radius: 99999px;
      p {
        color: $main-color4;
      }
    }
    // .MuiInputAdornment-root:not([class*="noValue"]) p {

    //   color: $main-color4;
    // }
  }
}
button.button.secondary.temp_secondary {
  margin-top: 30px;
}
// .settingFrame > .MuiAccordion-root {
//   background: none;
//   border: 0;
//   > .MuiCollapse-vertical {
//     background: #eff4ff;
//     box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.4);
//     border-radius: 10px;
//     padding-top: 15px;
//     position: relative;
//     top: 45px;
//   }
// }
