@import "../styles/theme";

.Hot {
  position: absolute;
  top: -16px;
  display: inline-block;
  background-image: linear-gradient(90deg, $main-color1, $main-color2);
  border-radius: 24px;
  border-bottom-left-radius: 0;
  color: $main-color4;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  white-space: nowrap;
  padding: 4px 10px;
  margin-left: 4px;
  zoom: 0.8;
  &Avault {
    background-image: linear-gradient(90deg, #db76ff, #00f4b9);
    font-weight: 500;
  }
}
