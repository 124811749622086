.DepositRewardsItem {
    border-radius: 16px;
    border: 3px solid rgba(255, 255, 255, 0.1);
    background-color: #35235E;
    // background-image: linear-gradient(
    //   126.6deg,
    //   rgba(255, 255, 255, 0.06) 28.69%,
    //   rgba(255, 255, 255, 0) 100%
    // );
    button.primary.large {
        width: 100%;
        margin-top: 8px;
    }
    .token-item {
        border-bottom: 1px solid rgba($color: #ebebeb, $alpha: 0.2);
        margin-bottom: 40px;
        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    }
}