@import "../styles/breakpoints";

.confirmTransaction2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  > div {
    width: 100%;
  }
  h2 {
    position: absolute;
    right: 0;
    top: 0;
  }
  .confirmTransaction2_main_text {
    margin-bottom: 35px;
  }
  .confirmTransStepBox {
    @include breakpoint-down(sm) {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  .confirmTransactionStep {
    // margin-bottom: 20px;
    // width: 10%;
  }
}
