.AccountDetails_top {
  // border: 1px solid #aaa;
  border-radius: 10px;
  padding: 20px;
  .MuiButton-root {
    // color: #011847!important;
    &:hover {
      background: none;
      opacity: 0.8;
    }
  }
}
