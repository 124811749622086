@import "../styles/theme";
@import "../styles/breakpoints";

.navTitle {
  display: flex;
  color: $main-color3;
  text-decoration: none;
  align-items: center;
  text-transform: capitalize;
}
.logo {
  width: 32px;
  height: 32px;
  @include breakpoint-only(xs) {
    width: 24px;
    height: 24px;
  }
}

.navs {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}
.item {
  display: flex;
  color: $main-color3 !important;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none !important;
  text-transform: capitalize;
  white-space: nowrap;
  cursor: pointer;
  &Big {
    font-size: 32px;
    line-height: 40px;
  }
  &Small {
    margin: 24px 24px 0 0;
  }
  &:hover,
  &Active {
    color: $main-color2 !important;
  }
}
.menu {
  &Item {
    padding: 0 !important;
    &:hover {
      background-color: rgba($main-color3, 0.1) !important;
    }
  }
  .item {
    padding: 6px 16px;
  }
}

// mobileNav
.mobileNavPopup {
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 64px 32px;
  text-transform: capitalize;
  @include breakpoint-up(sm) {
    padding-top: 80px;
  }
  &Bg {
    background-color: #150847;
    background-image: url(../assets/bg.png),
      linear-gradient(0deg, #ffcdec -83.19%, #150847 55.63%);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
  }
}
.navBtn {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 16px;
  &Line,
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: white;
    transition-duration: 0.3s;
  }
  &Line {
    top: 50%;
  }
  &::before {
    top: 0;
  }
  &::after {
    top: 100%;
  }
  &Active &Line {
    transform: rotateY(90deg);
  }
  &Active {
    border-color: transparent;
    &::before {
      top: 50%;
      transform: rotate(45deg);
    }
    &::after {
      top: 50%;
      transform: rotate(-45deg);
    }
  }
}
