@import "../styles/theme";
@import "../styles/breakpoints";

.swapPage {
  position: relative;
  color: var(--text);
  width: 100%;
  @include breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  // .swapTitle {
  //   margin-bottom: 0.8em;
  // font-weight: 700;
  // font-size: 20px;
  // line-height: 30px;
  // padding-left: 19px;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   span {
  //     display: flex;
  //     align-items: center;
  //     font-size: 14px;
  //     font-weight: 400;
  //     a {
  //       margin-left: 5px;
  //       font-weight: 700;
  //     }
  //     svg {
  //       margin-right: 3px;
  //     }
  //   }
  // }
  button:hover {
    cursor: pointer;
  }

  .swapForm {
    background: var(--background-element);
    border-radius: 20px;
    padding: 24px 40px 0;
    position: relative;
    @include breakpoint-down(sm) {
      border-radius: 12px;
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .swapFormContainer {
        flex-grow: 1;
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      // margin-top: 12px;
      width: 100%;
      align-items: baseline;
      // >div:nth-child(1){
      //   // display: flex;
      //   // align-items: center;
      // }
      @include breakpoint-down(sm) {
        &.directSwapItem {
          align-items: center;
          .tokenName {
            line-height: 40px;
            vertical-align: text-bottom;
            margin-left: 8px;
          }
        }
      }
    }
    // .directSwapItem {
    //   >div:nth-child(1){
    //     display: flex;
    //     align-items: center;
    //     img {
    //       margin-right: 1vw;
    //     }
    //   }
    //   align-items: center;
    // }
    .balanceContainer {
      position: relative;
      span,
      a {
        font-size: 12px;
      }
      a {
        font-weight: 700;
        color: var(--text-selected);
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  button.exchange {
    padding-right: 0;
    height: 20px;
    img {
      height: 20px;
    }
  }
  button.exchange_icon {
    position: absolute;
    left: 58%;
    top: -23px;
    width: 48px;
    height: 48px;
    border: 6px solid #fff;
    background: #eff4ff;
    border-radius: 50%;
    font-size: 27px;
    z-index: 1;
  }

  .virtualSwapInfoBubble {
    padding: 8px 16px;
    margin-top: 16px;
    border: 1px solid;
    border-radius: 8px;
    background: $primary40T;
    width: 100%;
    > svg {
      fill: var(--primary-foreground);
      margin-right: 8px;
    }
    @include breakpoint-down(sm) {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .exchangeWarning {
    color: $white;
    background-color: $text-red;
    padding: 8px;
    width: 100%;
    margin-top: 32px;
    display: flex;
    justify-content: center;
    @include breakpoint-down(sm) {
      margin-top: 0;
    }
  }

  .infoSection {
    width: 100%;
    display: flex;
    margin-top: 32px;
    .title {
      cursor: pointer;
      svg {
        margin-left: 10px;
        transform: norotate(0deg);
        &.upsideDown {
          transform: rotate(-180deg);
        }
      }
    }
  }

  .swap-banner {
    width: 100%;
    margin-top: 40px;
    cursor: pointer;
  }
  .advancedOptions {
    width: 100%;
    margin-bottom: 32px;

    .tableContainer {
      display: none;
      &.show {
        display: block;
      }
    }

    .table {
      width: 100%;
      background: var(--background-element);
      margin-top: 16px;
      border: 1px solid var(--outline);
      border-radius: 10px;
      padding: 16px 24px;

      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: flex-start;
      .parameter {
        margin: 2px 0;
      }
      .parameter .options {
        margin-top: 10px;
      }
    }
  }

  .error {
    width: 100%;
    color: $error;
    font-weight: $normal;
    text-align: center;
    margin-top: 8px;
    display: none;
  }

  .info {
    width: 100%;
    text-align: center;
    margin-top: 8px;
    display: none;
  }
  .showInfo,
  .showError {
    display: block;
  }

  .pendingSwaps {
    width: 100%;
    > .pendingSwapItem {
      width: 100%;
      border: 1px solid $primary-blue;
      border-radius: 10px;
      background: var(--primary-background);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      cursor: pointer;
      margin-bottom: 16px;
      .swapDetails {
        color: $primary-blue;
        font-weight: 700;
        font-size: 16px;
      }
      .swapTimeContainer {
        display: flex;
        align-items: center;
        > svg {
          margin-right: 10px;
        }
        span.time {
          font-size: 16px;
        }
      }
    }
  }
  .Swap_divider {
    position: absolute;
    width: 100%;
    left: 0;
    button {
      display: flex;
      padding: 0;
    }
    .MuiDivider-wrapper {
      padding: 0;
    }
  }
}
.progress_button_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 3%;
  margin-top: 14px;
  margin-bottom: 20px;
  button.button {
    flex-grow: 1;
    // gap: 10px;
  }
}
.opennclose {
  width: 100px;
  height: 48px;
  min-height: 48px !important;
  position: absolute !important;
  right: 0 !important;
  .MuiAccordionSummary-expandIconWrapper {
    color: #000;
  }
}

.swap_btn_wrapper {
  margin: 0 -40px;
  @include breakpoint-down(sm) {
    margin: 0 -24px;
  }
  button {
    margin-top: 25px;
    border-radius: 0;
    height: 64px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    font-size: 20px;
    @include breakpoint-down(sm) {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
}
.swap_button {
  width: 80%;
  margin-top: 30px;
  background: linear-gradient(136deg, #4b89ff 0%, #3fe2fa 100%);
}
.swap_connect_button {
  width: 80%;
  margin-top: 30px;
}
.swap_button.Mui-disabled {
  background: none;
}
.exchRate {
  display: flex;
  svg {
    height: 20px;
  }
}

.fix_shake.Mui-selected {
  border-left-width: 2px !important;
}
