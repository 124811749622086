@import "../styles/theme";

.TrendIcon {
  height: 12px;
  margin-right: 10px;
  vertical-align: middle;
  &Up {
    fill: $primary;
    transform: rotate(180deg);
  }
  &Down {
    fill: $text-red;
  }
}
