@import "../styles/theme";
@import "../styles/breakpoints";

.textSecondary {
  color: $main-color2;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.connectWalletBtn {
  margin: auto;
  margin-top: 16px;
  max-width: 300px;
}

.gradientText {
  background: linear-gradient(180deg, $main-color2 1.61%, $main-color1 98.38%);
  background-clip: text;
  color: transparent;
  font-weight: 400;
  font-size: 14px;
}

// mobile pool item
.MItem {
  border-top: 1px solid rgba($main-color3, 0.1);
  margin-top: 16px;
  padding-top: 24px;
  cursor: pointer;
  &Sub {
    color: $main-color3;
    text-align: left;
    margin-top: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  &Text {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    margin-top: 8px;
  }
}
