@import "../styles/theme";

.btn {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: $main-color3;
  border: 1px solid $main-color3;
  padding-left: 2px;
  padding-right: 10px;
  box-sizing: border-box;
  line-height: 32px;
  height: 32px;
  font-weight: 400;
  white-space: nowrap;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer;
}
