@import "../styles/theme";
@import "../styles/breakpoints";

.swapInputContainer {
  //border: 1px solid var(--outline-gray);
  // border-radius: 8px;
  // padding: 8px;
  width: 100%;
  position: relative;
  // background-color: var(--primary-background);
  @include breakpoint-up(sm) {
    display: flex;
  }
}

.selectGroup {
  //width: 200px;
  width: 30%;
  min-width: 150px;
  height: 56px;
  border-radius: 10px;
  // background-color: #eff4ff;
  display: flex;
  align-items: center;
  // padding-left: 1vw;
  margin-right: 6px;
  &.hoverPointer {
    &:hover {
      cursor: pointer;
    }
  }
  > img {
    width: 27%;
  }

  .tokenNameContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 8px;
  }
  .symbolArrowContainer {
    display: flex;
    align-items: center;
    svg path {
      fill: var(--primary-foreground);
    }
  }
}

.inputGroup {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  // background-color: #eff4ff;
  border-radius: 10px;
  cursor: default;
  input {
    cursor: default;
    outline: none;
    font-size: 24px;
    font-weight: 600;
    background-color: transparent;
    color: var(--primary-foreground);
    width: 100%;
    height: 100%;
    @include breakpoint-up(sm) {
      text-align: right;
      text-indent: 5%;
    }
    &::placeholder {
      color: #554979;
    }
  }
  &.focusable,
  &.focusable input {
    cursor: text;
  }
}

.textBoldPurple {
  outline: none;
  // color: #0f173c;
  font-weight: 600;
  font-size: 20px;
  white-space: nowrap;
}

.textMinor {
  margin-right: -9999px;
  font-size: 12px;
  color: var(--text);
}
