@import "../styles/theme.scss";
@import "../styles/breakpoints";

.ReviewExtraRewards {
  padding: 0 0 8vh;
  width: 600px;
  @include breakpoint-down(sm) {
    width: auto;
  }
  .content {
    width: 80%;
    margin: 0 auto;

    .row {
      display: flex;
      justify-content: space-between;
      margin-top: 2vh;
      margin-bottom: 4vh;
    }
  }
  .rer_switch {
    .MuiTypography-root {
      font-size: 14px !important;
    }
  }
  .grey {
    color: #aaa;
  }
  .error {
    color: #fb5a5a;
  }
}
