@import "../styles/theme";

.section1 {
  display: grid;
  grid-auto-flow: column;
  margin-top: 15%;
  padding: 28px 36px;
  border-radius: 15px;
  p {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
  }
}

.section2 {
  padding: 28px 36px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.18);
  display: grid;
  margin-top: 2rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  display: flex;
  max-width: 56rem;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  row-gap: 1rem;
  grid-auto-flow: row dense;
}

.inputContainer {
  border-radius: 8px;
  padding: 8px;
  display: flex;
  width: 100%;

  background: #eff4ff;
  &.disabled {
    background-color: #eff4ff;
    border: none;
    cursor: not-allowed;
  }

  input {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 0.25rem;
  }
}

.submitButton {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
  height: 3rem;
  border-radius: 0.125rem;
}

.popper {
  padding: 12px;
  background-color: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.18);
  border-radius: 15px;
  z-index: 10;
}

.section3 {
  display: grid;
  margin-top: 3rem;
  text-align: center;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  div {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    cursor: pointer;

    @media (min-width: 640px) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  img {
    display: inline-block;
    margin-left: 0.25rem;
  }
}

.tokenList {
  cursor: pointer;
  font-size: 14px;
  color: $main-color4;
}
