@import "../styles/theme";

.Paper:hover {
  border-color: rgba(white, 0.3);
}

.Hot {
  position: absolute;
  top: -11px;
  right: 40px;
  display: inline-block;
  color: $main-color4;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  white-space: nowrap;
  padding: 5px 10px 10px;
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: -4px;
    border: solid transparent;
    border-width: 4px 2px;
    border-left-color: #9b7091;
    border-bottom-color: #9b7091;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, $main-color1, $main-color2);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 70%, 0 100%);
  }
  &Avault {
    font-weight: 500;
    &::after {
      background-image: linear-gradient(90deg, #db76ff, #00f4b9);
    }
  }
}
