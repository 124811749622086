.MyShareCard {
  background: transparent;
  // box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.18);
  // border-radius: 15px;
  padding: 20px 20px;
  font-size: 14px;
  color: #ebebeb;
  border-top: 1px solid rgba($color: #ebebeb, $alpha: 0.2);
  .title {
    font-weight: 700;
    margin-bottom: 16px;
  }
  .label {
    color: #ebebeb;
    font-size: 12px;
  }
  .value {
    color: #ebebeb;
    font-size: 12px;
  }
}
