@import "../styles/theme.scss";
@import "../styles/breakpoints";

.gov-page {
  .right-box {
    border-radius: 16px;
    @include breakpoint-up(sm) {
      background-color: var(--primary-background);
    }
  }
  .lock-unlock-box {
    @include breakpoint-down(sm) {
      position: fixed;
      bottom: 0;
      width: 100%;
      display: block;
      .lock-unlock {
        margin: auto;
      }
    }
  }
  .alert {
    background-color: rgba(#ffcdec, 0.1);
    color: #ffcdec;
    padding: 8px 8px;
    border-radius: 8px;
    img {
      margin-right: 8px;
    }
  }
  .lock-unlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    .btn {
      border-radius: 72px;
      height: 100%;
      padding: 0 32px;
      text-decoration: none;
      font-weight: bold;
      cursor: pointer;
      &.active {
        background: #ffcdec;
        color: $main-color4;
        cursor: default;
      }
    }
    @include breakpoint-up(sm) {
      border: 1px solid rgba($color: #ebebeb, $alpha: 0.2);
      border-radius: 72px;
    }
    @include breakpoint-down(sm) {
      font-size: 14px;
      height: 42px;
      line-height: 42px;
      width: 90%;
      .btn {
        border: 1px solid $main-color2;
        width: 48%;
        text-align: center;
      }
    }
  }
  .percentage-btn {
    width: 104px;
    height: 32px;
  }
  .lock-period-item {
    border: 1px solid #ffcdec;
    color: #ffcdec;
    font-size: 12px;
    font-weight: 600;
    border-radius: 40px;
    cursor: pointer;
    height: 24px;
    line-height: 24px;
    width: 100%;
    display: inline-block;
    //   margin: 8px;
    &.selected {
      background-color: #ffcdec;
      color: #000;
    }
    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      border-color: rgba($color: #ffcdec, $alpha: 0.3);
      color: rgba($color: #ffcdec, $alpha: 0.3);
    }
  }
  .lock-btn {
    border-radius: 0;
    height: 64px;
    line-height: 64px;
    font-size: 20px;
    font-weight: 700;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .text-disabled {
    color: rgba($color: #ebebeb, $alpha: 0.3);
  }
  .flow-add-srs,
  .flow-add-time {
    cursor: pointer;
    border: 1px solid rgba($color: #ffcdec, $alpha: 0.2);
    border-radius: 16px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 340px;
    @include breakpoint-down(sm) {
      height: auto;
      min-height: 180px;
      margin-bottom: 16px;
    }
    &:hover {
      border-color: #ffcdec;
    }
    .shorten-lock {
      position: absolute;
      bottom: 8px;
      width: 100%;
    }
  }

  .unlock_tokeninput > div {
    cursor: default !important;
    input {
      cursor: default !important;
    }
  }
  .unlock_tokeninput > div > div {
    p {
      color: #fff !important;
    }
  }
}
