@import "../styles/theme";
@import "../styles/breakpoints";

// .farms {
//     width: 100%;
//     height: 68px;
//     background-color: #000;
.farmsInfo {
  display: grid;
  grid-auto-flow: column;
  margin-top: 32px;
  height: 120px;
  padding: 28px 36px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.18);
  div {
    text-align: center;
  }
}

.noValue {
  font-size: 28px;
  font-weight: 500;
  color: #aca8bc;
  line-height: 1.5;
}
.value {
  font-size: 28px;
  font-weight: 500;
  color: #00194a;
  line-height: 1.5;
}

.label {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}

div.claimDiv {
  text-align: center;
  button {
    margin: auto;
    width: 75% !important;
  }
  // button.button.large {
  //   width: 80%;
  //   margin: auto;
  // }
}

.ActionBar {
  width: 100%;
  background: #ffffff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.18);
  border-radius: 15px 15px 0px 0px;
  margin: 8px 0;
  font-size: 16px;
  font-weight: 600;
  height: 52px;
  padding: 0 24px;
  // padding: 22px 32px;
  div {
    // height: 24px;
    margin: auto;
    display: inline-block;
    // text-align: center;
    height: 52px;
    line-height: 52px;
  }
  .lp-name {
    width: 30%;
  }
}
// }

.FarmLayoutLeft {
  // height: 442px;
  padding: 20px 0;
  text-align: center;

  > * {
    margin-top: 3vh !important;
    // margin-bottom: 3vh!important;
  }
}
.FarmLayoutRight {
  position: relative;
  padding: 20px 40px;
  @include breakpoint-down(sm) {
    padding: 20px 20px;
  }
  cursor: pointer;
  .Farm_divider {
    margin: 15px -40px;
    @include breakpoint-down(sm) {
      margin: 15px -20px;
    }
  }
}
.FarmPanelRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px !important;
}

.FarmSvgGroup {
  svg,
  img {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    @include breakpoint-down(sm) {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
  }
}
.FarmAPYText {
  font-size: 32px;
  background: linear-gradient(180deg, #ffcdec 1.61%, #6c7dff 98.38%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
  line-height: 1.2;
  @include breakpoint-down(sm) {
    font-size: 20px;
  }
}
.farm_detail_h2 {
  font-size: 14px !important;
  position: relative;
  z-index: 2;
  font-weight: 700 !important;
  color: #ebebeb !important;

  svg {
    margin-right: 10px;
  }
}

.FarmDetailLeft {
  padding: 25px;
}

.bold {
  font-weight: 700 !important;
}

.color2 {
  color: $main-color2;
}

.FarmDetailIconGroup {
  svg,
  img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
}

.FarmDetailLeftApy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px !important;

  span:nth-child(2) {
    background: linear-gradient(180deg, #ffcdec 1.61%, #6c7dff 98.38%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}
.FarmDetailLeftBtn {
  margin-top: 16px;
  margin-bottom: 12px;
}
