$text-red: #f53535;
$text-black: #000000;
$text-white: #ebebeb;

$primary: #6c7dff;
$primary40T: #{$primary}40;
$primary-blue: #6c7dff;
$primary-blue-light: #eff4ff;
$primary-blue-dark: #554979;
$white: #ffffff;
$white30: #4c4c4c;
$black: #000000;
$black80: #333333;
$black30: #a5a5a5;
$error: #fb5a5a;
$yellow2: #ffd848;
$yellow55: #{$yellow2}8c;
$light-blue: #e8e5fb;
$dark-purple: #110040;
$blue: #2a4bff;
$indigo20T: #{$primary-blue}33;
$blue40T: #{$blue}66;
$yellow: #f2d35f;
$indigo1: #4b11f2;
$indigo2: #311188;
$indigo3: #35235e;
$indigo4: #070713;
$sunset: #d96a3b;
$sunburn: #e6ad76;
$sand1: #01beed;
$sand2: #01beed;
$sand3: #eff4ff;

$divider-red: #fb5a5a;

$disabled: #554979;

// Fonts
$font-family: "Poppins", sans-serif; //"Source Code Pro", monospace;
$font-family-title: "Poppins", sans-serif;

$light: 300;
$normal: 400;
$medium: 500;
$bold: 700;

$smallFont: 12px;

$shadow: 0px 2px 8px rgba(39, 14, 1, 0.18);
$shadow-hover: 2px 2px 12px rgba(68, 64, 64, 0.14);

$main-color1: #6c7dff;
$main-color2: #ffcdec;
$main-color3: #ebebeb;
$main-color4: #150847;
