@import "../styles/theme";
@import "../styles/breakpoints";

.Btn {
  &:hover {
    background-color: $main-color1 !important;
  }
  @include breakpoint-down("sm") {
    width: 100%;
    border: 1px solid $main-color1 !important;
    &s {
      display: block !important;
    }
    &Confirm {
      background-color: $main-color1 !important;
      margin: 16px 0 0 !important;
    }
  }
}
