@import "../styles/theme";
@import "../styles/breakpoints";

.deposit-withdraw-page {
  .MuiGrid-root.MuiGrid-item {
    padding-top: 16px;
  }
  .left-info {
    border-radius: 16px;
    @include breakpoint-down(sm) {
      border: none;
      background-image: none;
    }
    @include breakpoint-up(sm) {
      border: 3px solid rgba(255, 255, 255, 0.1);
      background-image: linear-gradient(
        126.6deg,
        rgba(255, 255, 255, 0.06) 28.69%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    .apy {
      background: linear-gradient(180deg, #ffcdec 1.61%, #6c7dff 98.38%);
      background-clip: text;
      color: transparent;
    }
  }
  .btns {
    border-bottom: 1px solid rgba(#ebebeb, 0.2);
  }
  .back-to-pools {
    font-weight: bold;
    font-size: 18px;
    a {
      text-decoration: none;
      font-weight: bold;
      font-size: 18px;
      &:hover {
        color: $primary;
      }
    }
    .arrows {
      display: inline-block;
      margin: 0 8px;
    }
  }
  .percentage-btn {
    width: 104px;
    height: 32px;
  }
  .token-btn {
    width: 80px;
    height: 32px;
  }
  .alert {
    background-color: rgba(#ffcdec, 0.1);
    color: #ffcdec;
    padding: 8px 8px;
    border-radius: 8px;
    img {
      margin-right: 8px;
    }
  }
  .pool-name {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: inline-block;
      border: 1px solid $primary;
      border-radius: 10px;
    }
    div {
      display: inline-block;
      margin-left: 8px;
    }
    a {
      font-size: 12px;
      font-weight: 400;
      color: #0d173e;
      text-decoration: none;
      &:hover {
        color: $primary;
      }
      & + img {
        border: none;
        margin-left: 4px;
        transform: translateY(2px);
        height: 12px;
        width: 12px;
      }
    }
  }
  .deposit-withdraw {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba($color: #ebebeb, $alpha: 0.2);
    border-radius: 72px;
    cursor: pointer;
    font-size: 12px;
    div {
      border-radius: 72px;
      height: 100%;
      padding: 0 32px;
      line-height: 32px;
      text-decoration: none;
      font-weight: bold;
      a {
        color: #fff;
        text-decoration: none;
      }
      &.active {
        background: #ffcdec;
        color: #000;
        cursor: default;
      }
    }
  }

  .go-to-swap {
    height: 40px;
    font-size: 18px;
    background: linear-gradient(136deg, #4b89ff 0%, #3fe2fa 100%);
    cursor: pointer;
    border-radius: 10px;
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-left: 8px;
    text-decoration: none;
    font-weight: bold;
  }

  .bonus {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  .use-lp {
    position: absolute;
    right: 0;
  }

  .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
    font-size: 12px;
    font-weight: 600;
  }

  .deposit-btn {
    border-radius: 0;
    height: 64px;
    line-height: 64px;
    font-size: 20px;
    font-weight: 700;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .withdraw-percentage-input {
    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary {
      width: 100px;
      height: 32px;
      // background: #eff4ff;
      padding-right: 0;
      font-size: 24px;
      font-weight: 700;
      border: none;
      input {
        text-align: center;
      }
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
      // border: 1px solid #01beed;
    }
  }

  .radio-group {
    border-radius: 10px;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
    &.deposit-main,
    &.withdraw-main {
      background: #35235e;
      border-radius: 16px;
      border: none;
      // width: 540px;
    }
  }
}
