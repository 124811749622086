@import "../styles/theme";
@import "../styles/breakpoints";

div.confirmTransaction {
  margin-top: 0;
  padding: 40px;
  text-align: center;
  border-radius: 16px;
  @include breakpoint-up(sm) {
    min-height: 350px;
    width: 540px;
  }
  @include breakpoint-down(sm) {
    height: 100%;
    display: flex;
    flex-direction: column;
    .iconFlexGrow1 {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
    }
  }
  h3 {
    font-family: $font-family-title;
    font-style: normal;
    // font-weight: $bold;
    font-size: 20px;
    margin-top: 10%;
  }
  .error-details {
    border: none;
    text-align: center;
    .MuiAccordionSummary-content {
      width: 100%;
      text-align: center;
      p {
        width: 100%;
        text-align: center;
        text-decoration: underline;
      }
    }
  }
  .MuiStep-root.MuiStep-horizontal {
    border: 1px solid #ebebeb;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding: 0;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.Mui-completed {
      background-color: #6c7dff;
      border-color: #6c7dff;
    }
    .MuiStepConnector-root.MuiStepConnector-horizontal {
      &.Mui-completed {
        color: #6c7dff;
      }
    }
  }
  .stake-lp-btn,
  .done-btn {
    width: 100%;
    margin-bottom: 16px;
  }
  a.stake-lp {
    text-decoration: none;
  }
  .stake-lp-btn {
    background: #ffcdec;
    color: #000;
    text-decoration-color: #ffcdec;
  }
  .done-btn {
    background: transparent;
    border: 1px solid #ffcdec;
  }
  .add-to-wallet {
    cursor: pointer;
  }
  .tx-link {
    font-size: 12px;
    color: #ebebeb;
    text-decoration-color: #ffcdec;
  }
}
