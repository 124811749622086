@import "../styles/theme.scss";
@import "../styles/viewports";

button.button {
  border-radius: 9999px;
  border: none;
  color: $text-white;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  cursor: pointer;
  padding: 1px 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;
    color: #35235e;
    // color: rgba(0, 0, 0, 0.2);
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &.primary {
    background: $primary;

    &:enabled:hover {
      background-color: $main-color2;
      color: $main-color4;

      // box-shadow: var(--shadow-hover);
    }
    &:disabled {
      background: $disabled;
    }
  }
  &.outline {
    background-color: transparent;
    border-color: $main-color2;
    border-width: 1px;
    border-style: solid;
    color: $main-color2;

    &:disabled {
      cursor: not-allowed;
      color: $disabled;
      border-color: $disabled;
    }

    &:enabled:hover {
      //background-color: var(--primary-accent);
      color: $main-color3;
      border-color: $main-color3;
      // box-shadow: $main-color3;
    }
    &.active {
      background-color: $main-color2;
      color: $main-color4;
      &:hover {
        color: $main-color4;
        border-color: $main-color2;
      }
    }
  }

  &.secondary {
    background-color: $main-color2;
    color: $main-color4;
    font-weight: 700;

    &:disabled {
      cursor: not-allowed;
      color: var(--text-secondary-button-disabled);
    }

    &:enabled:hover {
      background-color: $main-color3;
      color: $main-color4;
    }
  }

  &.ternary {
    color: var(--text);
    background: var(--emphasis-1);
    border: 1px solid var(--emphasis-1);
    font-weight: normal;

    &:disabled {
      cursor: not-allowed;
      color: var(--text-secondary-button-disabled);
    }

    &:enabled:hover,
    &:enabled:focus {
      background-color: var(--emphasis-2);
    }
  }

  &.ghost {
    color: var(--text);
    background: var(--background);
    border: 1px solid var(--emphasis-1);
    font-weight: normal;

    &:disabled {
      cursor: not-allowed;
      color: var(--text-secondary-button-disabled);
    }

    &:enabled:hover,
    &:enabled:focus {
      background-color: var(--emphasis-1);
    }
  }

  &.temporary {
    background: $yellow;
    color: $black;

    &:disabled {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.2);
    }

    &:enabled:hover,
    &:enabled:focus {
      background-color: $primary-blue;
      color: $text-white;
      box-shadow: var(--shadow-hover);
    }
  }

  &.cancel {
    background: $white;
    color: $primary;
    &:enabled:hover,
    &:enabled:focus {
      color: $primary-blue;
    }
  }

  &.small {
    // width: 176px;
    min-height: 24px;
    font-size: 12px;
    // @include breakpoint(md) {
    //   width: 140px;
    // }
  }

  &.large {
    width: 176px;
    min-height: 40px;
    @include breakpoint(md) {
      width: 140px;
    }
  }

  &.medium {
    padding: 8px;
    min-height: 32px;
  }

  &.fullWidth {
    width: 100%;
  }
}
