@import "../styles/theme";
@import "../styles/breakpoints";

@font-face {
  font-family: Avenir-Next-Bold;
  src: url(../assets/fonts/Avenir-Next-Bold.ttf);
}

.BoxPadding {
  padding: 32px;
  @include breakpoint-down(sm) {
    padding: 24px;
  }
}
.TextMuted {
  color: $disabled;
}

// effective on mobile
.tab {
  position: relative;
  font-size: 14px;
  font-weight: bolder;
  padding: 10px 24px;
  color: $main-color2;
  min-height: 40px;
  &Item {
    cursor: pointer;
    &Right {
      cursor: pointer;
      position: absolute;
      right: 24px;
    }
  }
  @include breakpoint-up(sm) {
    display: none;
  }
}

.LeftBox {
  text-align: center;
  @extend .BoxPadding;
  &Icon {
    width: 96px;
    height: 96px;
    object-fit: contain;
  }
}
.Countdown {
  font-family: "Avenir-Next-Bold", Roboto, monospace, sans-serif;
  letter-spacing: 1px;
}
.TimeLabel {
  color: white;
  opacity: 0.5;
  margin-top: -2px;
}
.TimeSpace {
  display: inline-block;
  width: 12px;
  @include breakpoint-down(md) {
    width: 8px;
  }
  @include breakpoint-down(sm) {
    width: 5px;
  }
}

.RightBox {
  border-radius: 16px;
  overflow: hidden;
  @include breakpoint-up(sm) {
    background-color: var(--primary-background);
  }
}
.ArrowDown {
  display: flex;
  align-items: center;
  transition: 0.3s;
  &Icon {
    fill: $main-color2;
  }
  &Active {
    transform: rotate(180deg);
  }
}
.trendIcon {
  height: 12px;
  margin-right: 10px;
  vertical-align: middle;
  &Up {
    fill: $primary;
    transform: rotate(180deg);
  }
  &Down {
    fill: $text-red;
  }
}
.TokenIcon {
  width: 24px;
  height: 24px;
}

.SelectOption {
  padding: 16px 24px;
  &:hover {
    background-color: var(--option-hover);
    cursor: pointer;
  }
  + .SelectOption {
    border-top: 1px solid rgba($main-color3, 0.1);
  }
}

.VoteIcon {
  fill: $main-color3;
  width: 30px;
}
.VoteAmount {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  &Arrow {
    fill: $disabled;
    margin: 0 4px;
  }
  &Big {
    flex-direction: column;
    font-size: 16px;
    .VoteAmountArrow {
      transform: rotate(90deg);
      width: 12px;
    }
  }
  @include breakpoint-up(sm) {
    position: absolute;
    top: 0;
    right: 0;
  }
}

// percentage button
.PctBtns {
  margin-top: 24px;
  justify-content: "space-between";
}
.PctBtn {
  flex-grow: 1;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  border-radius: 16px;
  text-align: center;
  cursor: pointer;
  color: $main-color2;
  border: 1px solid $main-color2;
  &:hover {
    color: $main-color3;
    border-color: $main-color3;
  }
  &Active {
    &,
    &:hover {
      color: $main-color4;
      background-color: $main-color2;
    }
  }
  &Disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.VoteBtn {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  padding: 16px;
  text-align: center;
  background-color: $main-color1;
  color: $main-color3;
  cursor: pointer;
  transition: 0.25s;
  &:hover {
    background-color: $main-color2;
    color: $main-color4;
  }
  &Disabled {
    background-color: $disabled;
    color: $indigo3;
    pointer-events: none;
  }
  @include breakpoint-down(sm) {
    border-radius: 0 0 16px 16px;
    &Mobile {
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }
}
