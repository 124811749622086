@import "../styles/theme.scss";

.balanceContainer {
  text-align: right;
  span,
  a {
    font-size: 12px;
  }
  a {
    font-weight: 700;
    color: var(--text-selected);
    &:hover {
      cursor: pointer;
    }
  }
}

.tokenInputContainer {
  // border: 1px solid var(--outline-gray);
  // border-radius: 8px;
  padding: 8px;
  display: flex;
  width: 100%;
  position: relative;
  // background-color: var(--primary-background);

  background: transparent;
  padding-left: 48px;
  .textDisabled {
    color: rgba($color: #ebebeb, $alpha: 0.3);
  }
  &.showBorderBottom {
    border-bottom: 1px solid rgba($color: #ebebeb, $alpha: 0.1);
  }
  &.disabled {
    background-color: transparent;
    border: none;
    cursor: not-allowed;
  }

  .smallText {
    font-size: 12px;
  }

  .boldText {
    font-weight: $bold;
    line-height: 24px;
    font-size: 20px;
  }

  .tokenSymbolAndName {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    flex: 1 1 20%;
  }

  img {
    vertical-align: text-bottom;
    width: 40px;
    height: 40px;
    margin: auto 0;
  }
}

.maxBtn {
  height: 24px;
  min-width: 60px;
  padding: 0 8px;
  border-radius: 16px;
  position: absolute;
  top: 12px;
  right: 16px;
  color: #ffcdec;
  background: transparent;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border: 1px solid #ffcdec;
  &.maxBtnDisabled {
    border: 1px solid rgba(#ffcdec, 0.3);
    color: rgba(#ffcdec, 0.3);
    cursor: not-allowed;
    pointer-events: none;
  }
  &:hover {
    background-color: #ffcdec;
    color: #000;
  }
}

.inputGroup {
  display: flex;
  flex: 1 1 60%;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  input {
    cursor: text;
    outline: none;
    text-align: right;
    height: 48px;
    font-weight: 700;
    border-radius: 10px;
    background-color: var(--primary-background);
    color: var(--primary-foreground);
    width: 100%;
    font-size: 24px;
    padding-right: 48px;
    &.showMax {
      padding-right: 100px;
    }
    &.errorInput {
      color: #ff0028;
    }
    &::placeholder {
      color: var(--text-lighter);
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      // background-color: #fff;
      // border: none;
      cursor: not-allowed;
    }
  }
}
