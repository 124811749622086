.gov_info_icon {
  width: 40px;
  margin-right: 10px;
  vertical-align: middle;
}
.gov_info_Row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  span:nth-child(2) {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 4px;
    }
  }
}
.gov_info_srs_text {
  background: linear-gradient(180deg, #ffcdec 1.61%, #6c7dff 98.38%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 400;
  font-size: 12px;
}
